import React, { useEffect, useState } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { useLocation } from "react-router-dom";
import img1 from "../../../../assets/img/strata1.jpg";
import img2 from "../../../../assets/img/strata2.jpg";
import "../../styleAllServices.css";

export default function CommercialLandscapeMaintenance() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "Commercial Landscape Maintenance | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Enhance the beauty and functionality of your outdoor space with expert strata landscape maintenance services from BUR-HAN. Our comprehensive solutions ensure a thriving and picturesque environment year-round, backed by a commitment to personalized service and eco-friendly practices.";
    setPageDescription(newDescription);

    const newKeywords = [
      "strata landscape maintenance, pesticide-free landscaping, BUR-HAN Garden & Lawn Care, Vancouver lawn care, sustainable landscaping, organic lawn care, landscape services, property maintenance, Vancouver BC landscaping, strata lawn care, landscape enhancement, eco-friendly landscaping, garden maintenance, outdoor space maintenance",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Strata Landscape Maintenance</h1>
      </div>
      <div className="serviceItemsMain">
      <h2 data-aos="fade-down">
          "Enhance the beauty and function of your outdoor space with our expert
          lawn care services."
        </h2>
        <hr />
      
        {/* -------------- serviceImages SECTION -------------*/}
        <section className="section-container">
              <div className="image-container px-5" data-aos="fade-right">
                <img src={img1} alt="grubs" />
              </div>
              <div className="text-container px-5" data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                Strata Landscape Maintenance 
                </h3>
                <p style={{ lineHeight: "1.5" }}>
                Partner with a team you can trust to deliver quality landscape services on time and within your budget, while keeping you updated every step of the way. Say goodbye to the hassle of micromanaging an underperforming landscape partner. Our reliable team and full-service maintenance plans are designed to keep your property safe, healthy, and beautiful all year long.

                </p>
              </div>
            </section>
        {/* -------------- BANNER SECTION -------------*/}

        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- about SECTION -------------*/}
       
        <section className="section-container">
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
            Why Choose BUR-HAN Garden & Lawn Care?
            </h3>
            <ul>
              <li>
                <strong>Experienced Professionals: </strong>  With nearly three decades of experience, our team has the expertise to handle all aspects of commercial landscape maintenance, ensuring top-notch service every time.

              </li>
              <li>
                {" "}
                <strong>Year-Round, Complete Service Plans: </strong> We provide comprehensive maintenance plans that cover all your landscaping needs throughout the year, ensuring your lawns, shrubs, plants, trees, hedges, and garden beds are well-cared for.

              </li>
              <li>
                {" "}
                <strong>Real-Time Reporting:</strong> Stay informed with real-time reports detailing the work done on each visit, providing transparency and peace of mind.

              </li>
              <li>
                <strong>Consistent Personnel: </strong> Enjoy the reliability of having the same dedicated professionals caring for your property on each visit, fostering familiarity and trust.

              </li>
              <li>
                <strong>Seasonal Planting Options: </strong>  Enhance your garden's beauty with our seasonal planting services, adding color and vibrancy throughout the year.
              </li>
              <li><strong>Health and Safety: </strong> We are committed to keeping your outdoor spaces safe and healthy for your family and the environment. With local laws preventing the use of cosmetic pesticides, our pesticide-free approach ensures that your property stays in harmony with these regulations, all while protecting what matters most—your loved ones and nature."
              </li>
              <li>
                <strong>Landscape Enhancement Specialist: </strong> Access expert advice and suggestions from our landscape enhancement specialist to elevate the aesthetics and functionality of your outdoor space.

              </li>
              <li>
                <strong>Dedicated Maintenance Supervisor:</strong> A dedicated landscape maintenance supervisor will visit your property monthly to ensure the highest quality of work from our crews.

              </li>
              
            </ul>
          </div>
          <div className="image-container" data-aos="fade-left">
            <img src={img2} alt="lanscape maintenance" />
          </div>
        </section>
        {/* -------------- END OF BANNER -------------*/}
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3>At BUR-HAN Garden & Lawn Care</h3>
                <p>
                 We take pride in offering personalized, high-quality landscape maintenance services tailored to the unique needs of each client. Let us help you create and maintain a beautiful, thriving outdoor environment that you can enjoy year-round.
For more information or to schedule a consultation, contact us today!

                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
