import React, { useEffect, useState } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { useLocation } from "react-router-dom";
import fall1 from "../../../../assets/img/grass.jpeg"
import img1 from "../../../../assets/img/grass.jpeg";
import img2 from "../../../../assets/img/grass3.jpg";
import one from "../../../../assets/img/one.svg";
import two from "../../../../assets/img/two.svg";
import three from "../../../../assets/img/three.svg";
import four from "../../../../assets/img/four.svg";
import "../../styleAllServices.css";

export default function CommercialLandscapeMaintenance() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "Commercial Landscape Maintenance | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Enhance the beauty and functionality of your outdoor space with expert landscape maintenance services from BUR-HAN. Our comprehensive solutions ensure a thriving and picturesque environment year-round, backed by a commitment to personalized service and eco-friendly practices.";
    setPageDescription(newDescription);

    const newKeywords = [
      "residential landscape maintenance, pesticide-free landscaping, BUR-HAN Garden & Lawn Care, Vancouver lawn care, sustainable landscaping, organic lawn care, landscape services, property maintenance, Vancouver BC landscaping, residential lawn care, landscape enhancement, eco-friendly landscaping, garden maintenance, outdoor space maintenance",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Residential Landscape Maintenance</h1>
      </div>
      <div className="serviceItemsMain">
      <h2 data-aos="fade-down">
          "Enhance the beauty and function of your outdoor space with our expert
          lawn care services."
        </h2>
        <hr />
      
        {/* -------------- serviceImages SECTION -------------*/}
        <section className="section-container">
              <div className="image-container px-5" data-aos="fade-right">
                <img src={fall1} alt="grubs" />
              </div>
              <div className="text-container px-5" data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                Residential Landscape Maintenance
                </h3>
                <p style={{ lineHeight: "1.5" }}>
                Since 1995, BUR-HAN Garden & Lawn Care has been a trusted name in professional landscape maintenance, providing year-round services for residential clients. Our extensive experience and commitment to quality make us the go-to choice for homeowners seeking to maintain and enhance their outdoor spaces.

                </p>
              </div>
            </section>
        {/* -------------- BANNER SECTION -------------*/}

        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- about SECTION -------------*/}
       
        <section className="section-container">
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
            Why Choose BUR-HAN Garden & Lawn Care?
            </h3>
            <ul>
              <li>
                <strong>Experienced Professionals: </strong>  With nearly three decades of experience, our team has the expertise to handle all aspects of commercial landscape maintenance, ensuring top-notch service every time.

              </li>
              <li>
                {" "}
                <strong>Year-Round, Complete Service Plans: </strong> We provide comprehensive maintenance plans that cover all your landscaping needs throughout the year, ensuring your lawns, shrubs, plants, trees, hedges, and garden beds are well-cared for.

              </li>
              <li>
                {" "}
                <strong>Real-Time Reporting:</strong> Stay informed with real-time reports detailing the work done on each visit, providing transparency and peace of mind.

              </li>
              <li>
                <strong>Consistent Personnel: </strong> Enjoy the reliability of having the same dedicated professionals caring for your property on each visit, fostering familiarity and trust.

              </li>
              <li>
                <strong>Seasonal Planting Options: </strong>  Enhance your garden's beauty with our seasonal planting services, adding color and vibrancy throughout the year.
              </li>
              <li><strong>Health and Safety: </strong>We are committed to keeping your outdoor spaces safe and healthy for your family and the environment. With local laws preventing the use of cosmetic pesticides, our pesticide-free approach ensures that your property stays in harmony with these regulations, all while protecting what matters most—your loved ones and nature."
              </li>
              <li>
                <strong>Landscape Enhancement Specialist:</strong>  Access expert advice and suggestions from our landscape enhancement specialist to elevate the aesthetics and functionality of your outdoor space.

              </li>
              <li>
                <strong>Dedicated Maintenance Supervisor:</strong> A dedicated landscape maintenance supervisor will visit your property monthly to ensure the highest quality of work from our crews.

              </li>
              
            </ul>
          </div>
          <div className="image-container px-5" data-aos="fade-left">
            <img src={img2} alt="landscape maintenance" />
          </div>
      
        </section>

        <section className="ourValues" style={{backgroundColor:"rgba(0,0,0,0.1)"}}>
         
        <h2 className="section-header my-1 mb-3"  data-aos="zoom-in">
        How It Works: Getting Started with Your Residential Landscape Maintenance Plan
        <h5 className="text-center mt-2" style={{ fontSize:"1.35vw", fontFamily:"din-light"}}>At BUR-HAN, we make the process simple and stress-free, ensuring your landscape gets the expert care it deserves.
        </h5>
        </h2>
        <div className="row mb-1">
          <div
            className="col-3 "
            data-aos="fade-right"
          >
            <div className="content" style={{marginLeft:".3vw" }}>
              <img src={one} style={{ width: "4.3rem"}} alt="number 1" className="mb-3" />
              <h2 className="numberTitle">Request a Quote:</h2>
              <p style={{width:"80%", fontFamily:"din-light"}} > Use our "Get a Quote" button
              to answer a few quick questions about
              your property, or simply give us a call at 
              
            604-983-2687. We'll take care of the rest!</p>
            </div>
          </div>
          <div
            className="col-3 "
            data-aos="zoom-in"
          >
            <div className="content ">
              <img src={two} style={{ width: "4.3rem" }} alt="number 2" className="mb-3" />
              <h2  className="numberTitle">Site Visit:</h2>
              <p style={{width:"80%", fontFamily:"din-light"}} > Our professional estimators will promptly schedule a visit, in person or independently, ensuring your property receives personalized attention.
              </p>
            </div>
          </div>
          <div
            className="col-3 "
            data-aos="fade-left"
          >
            <div className="content">
              <img src={three} style={{ width: "4.3rem" }} alt="number 3" className="mb-3" />
              <h2 className="numberTitle">Proposal Creation:</h2>
              <p style={{width:"80%", fontFamily:"din-light"}} > After carefully measuring and assessing your property, our estimator will prepare a detailed proposal tailored to your specific needs.
              </p>
            </div>
          </div>
          <div
            className="col-3 "
            data-aos-delay="300"
            data-aos="fade-right"
          >
            <div className="content">
              <img src={four} style={{ width: "4.3rem" }} alt="number 4" className="mb-3" />
        <h2 className="numberTitle">Review and Approve:</h2>
              <p style={{width:"80%", fontFamily:"din-light"}} > The proposal will be sent to you for your review. If everything meets your expectations, a simple phone call or email is all it takes to start your service.
              </p>
            </div>
          </div>
        </div>
        
      </section>       
        <div className="banner-section2">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-8  ">
                <h3 className="sndheading">
                Get Started Today!</h3>

              </div>
              <div className="col-4  ctasection  ">
                <div className="call-to-action ">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
              </div>
            </div>
          </div>
        </div>
</div>
        <Footer />
      </div>
    </div>
  );
}
